import { IconLayer, ScatterplotLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ChevronRight, ExpandLess, ExpandMore, FilterList } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Layout from 'components/Layout';
import { blackM } from 'controlTower/chartUtils/colors';
import { format } from 'date-fns';
import branchIcon from 'images/branch.png';
import branchOIcon from 'images/branchO.png';
import produce from 'immer';
import _ from 'lodash';
import pAll from 'p-all';
import React, { useCallback, useMemo, useReducer, useState } from 'react';
import { StaticMap } from 'react-map-gl';
import { useQuery } from 'react-query';
import Select, { VirtualSelect } from 'retailerUniverse/Select';
// import { useFetch, useInterval, useCurrencyFormatter } from 'utils/customHooks';
import { ga, useGState } from 'state/store';
import fetch from 'utils/fetch';
import { byId, haversine } from 'utils/utils';

// const MAPBOX_ACCESS_TOKEN = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;

const drawerWidth = 350;
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  expand: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  Select: {
    width: drawerWidth - 35,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    color: 'black',
  },
  ListText: {
    paddingLeft: theme.spacing(1),
  },
  tableCell: {
    paddingRight: '4px !important',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
  },
  image: {
    width: '50%',
    height: '50%',
  },
}));

export default (props) => (
  <Layout {...props} hideFilters>
    <Retailers />
  </Layout>
);

const c = {
  SMALL: 'Small',
  LARGE: 'Large',
  MM: 'MM',
  SUBD: 'SubD',
  MEDIUM: 'Medium',
  WS: 'WS',
  NONE: 'None',
};

export const grouping = {
  'Small C Traditional': c.SMALL,
  'Large B Pharmacy': c.LARGE,
  'MM 1': c.MM,
  'Semi WS Traditional': c.WS,
  'Small B Traditional': c.SMALL,
  'SubD A': c.SUBD,
  SubD: c.SUBD,
  'New Traditional': c.SMALL,
  'MM 2': c.MM,
  'Medium Pharmacy': c.MEDIUM,
  'Small A Traditional': c.SMALL,
  'Semi WS Beauty & Pharmacy': c.WS,
  'Small A Pharmacy': c.SMALL,
  'Medium Beauty': c.MEDIUM,
  'WS Beauty & Pharmacy': c.WS,
  'Large B Traditional': c.LARGE,
  'Large A Traditional': c.LARGE,
  'WS Traditional': c.WS,
  'New Pharmacy': c.SMALL,
  'Large A Pharmacy': c.LARGE,
  Speciality: c.SMALL,
  Hyper: c.MM,
  Super: c.MM,
  vistar: c.vistar,
  'Large A Beauty': c.LARGE,
  'Small A Beauty': c.SMALL,
  'Medium Traditional': c.MEDIUM,
  'Mini Market': c.MM,
  undefined: c.NONE,
  '    ': c.NONE,
};

export const colors = [
  [0, 51, 102],
  [0, 153, 153],
  [0, 0, 255],
  [102, 0, 102],
  [51, 204, 51],
  [204, 0, 153],
  [204, 0, 0],
  [255, 102, 0],
  [230, 153, 0],
  [102, 102, 51],
  [138, 43, 226],
  [164, 92, 103],
  [255, 99, 132, 255],
  [75, 192, 192, 255],
  [54, 162, 235, 255],
  [255, 193, 7, 255],
  [102, 26, 36],
  [217, 108, 123],
  [102, 77, 80],
  [217, 0, 58],
  [255, 191, 208],
  [178, 0, 95],
  [115, 57, 96],
  [77, 19, 65],
  [0, 0, 140],
  [255, 128, 229],
  [191, 0, 179],
  [201, 153, 204],
  [129, 0, 242],
  [27, 0, 51],
  [113, 89, 179],
  [0, 0, 115],
  [86, 94, 115],
  [0, 102, 255],
  [172, 195, 230],
  [0, 68, 128],
  [0, 145, 217],
  [0, 34, 51],
  [0, 31, 77],
  [0, 0, 153],
  [51, 173, 204],
  [0, 71, 77],
  [0, 242, 226],
  [86, 115, 109],
  [38, 153, 115],
  [61, 242, 157],
  [172, 230, 203],
  [22, 89, 49],
  [35, 140, 35],
  [7, 51, 0],
  [65, 242, 0],
  [225, 255, 191],
  [184, 217, 54],
  [85, 89, 22],
  [140, 131, 0],
  [230, 218, 172],
  [102, 97, 77],
  [64, 43, 0],
  [229, 172, 57],
  [255, 136, 0],
  [115, 61, 0],
  [178, 137, 89],
  [217, 98, 54],
  [102, 46, 26],
  [178, 113, 89],
  [255, 208, 191],
  [51, 7, 0],
  [255, 0, 0],
  [166, 0, 0],
];

const smallText = (text, color = 'textPrimary') => (
  <Typography variant="caption" color={color}>
    {text}
  </Typography>
);

const TC = ({ text, align = 'left', color }) => {
  const classes = useStyles();
  return (
    <TableCell className={classes.tableCell} align={align}>
      {smallText(text, color)}
    </TableCell>
  );
};

const TR = ({ label, value, image }) => {
  const classes = useStyles();
  return (
    <TableRow>
      {image ? (
        <TableCell className={classes.image}>
          <img src={image} width="150" height="150"></img>
        </TableCell>
      ) : (
        <>
          <TC text={label}></TC>
          <TC text={value} align="center"></TC>
        </>
      )}
    </TableRow>
  );
};

export const filterReducer = (state, [action, details]) =>
  produce(state, () => {
    switch (action) {
      case 'SET_DATA':
        return {
          ...state,
          universe: details,
        };
      case 'SET_FILTERED_DATA':
        return {
          ...state,
          filteredRetailerData: details,
        };
      case 'UPDATE_BRANCH':
        return {
          ...state,
          filters: {
            ...state.filters,
            branches: details,
          },
        };
      case 'UPDATE_CODE':
        return {
          ...state,
          filters: {
            ...state.filters,
            code: details,
          },
        };
      case 'UPDATE_NAME':
        return {
          ...state,
          filters: {
            ...state.filters,
            name: details,
          },
        };
      case 'UPDATE_PHONE':
        return {
          ...state,
          filters: {
            ...state.filters,
            phone: details,
          },
        };
      case 'UPDATE_CHANNEL':
        return {
          ...state,
          filters: {
            ...state.filters,
            channel: details,
          },
        };
      case 'UPDATE_GEOTAGBY':
        return {
          ...state,
          filters: {
            ...state.filters,
            geotagBy: details,
          },
        };
      case 'UPDATE_GEOTAGON':
        return {
          ...state,
          filters: {
            ...state.filters,
            geotagOn: details,
          },
        };
      case 'UPDATE_RETAG':
        return {
          ...state,
          filters: {
            ...state.filters,
            retag: details,
          },
        };
      case 'UPDATE_PHONE_VERIFIED':
        return {
          ...state,
          filters: {
            ...state.filters,
            phoneVerified: details,
          },
        };
      case 'HAS_IMAGE':
        return {
          ...state,
          filters: {
            ...state.filters,
            hasImage: details,
          },
        };
      case 'UPDATE_MKTINDEX':
        return {
          ...state,
          filters: {
            ...state.filters,
            mktIndex: details,
          },
        };
      case 'UPDATE_MKTNAME':
        return {
          ...state,
          filters: {
            ...state.filters,
            mktName: details,
          },
        };
      case 'UPDATE_DSE':
        return {
          ...state,
          filters: {
            ...state.filters,
            dse: details,
          },
        };
      default:
        throw new Error();
    }
  });

function Retailers() {
  const MAPBOX_ACCESS_TOKEN = useGState((s) => s[ga.PANEL_CONFIG].MAPBOX_ACCESS_TOKEN);

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tooltip, setTooltip] = useState();
  const [showDiv, setShowDiv] = useState(false);
  const [hovering, setHovering] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onMapClick = () => {
    if (!hovering) setShowDiv(false);
  };
  const [retailersExpand, setRetailersExpand] = useState(true);
  const handleRetailersClick = () => {
    setRetailersExpand(!retailersExpand);
  };
  const [geotaggingExpand, setGeotaggingExpand] = useState(true);
  const handleGeotaggingClick = () => {
    setGeotaggingExpand(!geotaggingExpand);
  };
  const [beatsExpand, setBeatsExpand] = useState(true);
  const handleBeatsClick = () => {
    setBeatsExpand(!beatsExpand);
  };
  const [shopExpand, setShopExpand] = useState(true);
  const handleShopClick = () => {
    setShopExpand(!shopExpand);
  };

  const [groupByValue, setGroupByValue] = useState('Branch');
  const handleGroupBy = (event) => {
    setGroupByValue(event.target.value);
  };

  const nodes = useGState((s) => s.branches);
  const branches = useMemo(
    () =>
      nodes
        .filter((node) => node.isLeaf)
        .map((node) => ({
          ...node,
          longitude: node?.locations?.[0]?.longitude,
          latitude: node?.locations?.[0]?.latitude,
        })),
    [nodes]
  );

  const [state, dispatch] = useReducer(filterReducer, {
    universe: null,
    filters: {
      branches: null,
      code: null,
      name: null,
      phone: null,
      channel: null,
      geotagBy: null,
      geotagOn: null,
      retag: null,
      phoneVerified: null,
      hasImage: null,
      mktIndex: null,
      mktName: null,
      dse: null,
    },
    filteredRetailerData: null,
  });

  const { data: retailersData } = useQuery(
    branches.length && ['retailers', { branches }],
    (_, { branches }) =>
      pAll(
        branches
          .filter((branch) => branch.id !== -1)
          .map((branch) => () => fetch(`/client/metrics/retailer?branchId=${branch.id}`)) || [],
        { concurrency: 3 }
      ),
    { suspense: false }
  );

  const { data: salesData } = useQuery(
    branches.length && ['sales', { branches }],
    (_, { branches }) =>
      pAll(
        branches
          .filter((branch) => branch.id !== -1)
          .map((branch) => () => fetch(`/client/metrics/salesman?branchId=${branch.id}`)) || [],
        { concurrency: 3 }
      ),
    { suspense: false }
  );

  //Grouping
  const getColor = useCallback(
    (d) => {
      if (groupByValue === 'Branch') {
        let index = _.orderBy(branches, 'id', 'asc').findIndex((b) => b.id === d.branchId);
        let out =
          index > colors.length
            ? [255, 50, 50]
            : branches.length === 1
            ? colors[index]
            : colors[index - 1];
        return out;
        //   return colors[index - 1];
        // let index = _.findIndex(groupItems, g => g[0] == d.branchId);
      } else return [255, 50, 50];
    },
    [branches, groupByValue]
  );

  const processedData = useMemo(() => {
    if (!retailersData) return;
    let data = _.flatten(
      retailersData.map((rs, index) =>
        rs.map((r) => ({
          ...r,
          branchId: branches[index].id,
        }))
      )
    );
    dispatch(['SET_DATA', data]);
    dispatch(['SET_FILTERED_DATA', data]);
    //console.log([...new Set(data.map(f => f.channel))]);
    // console.log(data.filter(f => !f.channel));
    // console.log(data.filter(f => f.channel == '    '));
    //console.log(data.filter(f => !f.images || !f.images.length));
    //console.log([...new Set(data.map(f => f.phone))]);
    return data;
  }, [branches, retailersData]);

  const processedSalesData = useMemo(() => {
    if (!salesData) return;
    let data = salesData
      .map((sd, index) =>
        sd.map((s) => ({
          ...s,
          branchId: branches[index].id,
        }))
      )
      .flat();
    return data;
  }, [branches, salesData]);

  const latLongData = useMemo(() => {
    if (!processedData) return;
    return processedData.filter((p) => p.latitude && p.longitude);
  }, [processedData]);

  const calculateInitialMapState = (locations) => {
    if (!locations || !locations.length) return null;

    const metrics = locations.reduce(
      (acc, loc) => ({
        centerLong: acc.centerLong + loc.long / locations.length,
        ...(loc.long > acc.maxLong ? { maxLong: loc.long } : { maxLong: acc.maxLong }),
        ...(loc.long < acc.minLong ? { minLong: loc.long } : { minLong: acc.minLong }),

        centerLat: acc.centerLat + loc.lat / locations.length,
        ...(loc.lat > acc.maxLat ? { maxLat: loc.lat } : { maxLat: acc.maxLat }),
        ...(loc.lat < acc.minLat ? { minLat: loc.lat } : { minLat: acc.minLat }),
      }),
      {
        centerLong: 0,
        maxLong: locations[0].long,
        minLong: locations[0].long,
        centerLat: 0,
        maxLat: locations[0].lat,
        minLat: locations[0].lat,
      }
    );

    const {
      centerLat: latitude,
      centerLong: longitude,
      minLat,
      minLong,
      maxLat,
      maxLong,
    } = metrics;
    const distance = haversine(minLat, minLong, maxLat, maxLong);
    return { latitude, longitude, zoom: Math.log(591657550.5 / distance) - 0.2 };
  };

  const initialViewState = useMemo(
    () => ({
      pitch: 0,
      bearing: 0,
      ...calculateInitialMapState(
        latLongData && latLongData.map((u) => ({ lat: u.latitude, long: u.longitude }))
      ),
    }),
    [latLongData]
  );

  const branchData = useMemo(() => {
    if (!processedData) return;
    if (!state.universe || !state.filters) return processedData;
    return state.filters.branches && state.filters.branches.length
      ? state.universe.filter(
          (r) => state.filters.branches.map((d) => d.value).indexOf(r.branchId) != -1
        )
      : state.universe;
  }, [processedData, state.universe, state.filters]);

  const branchSalesData = useMemo(() => {
    if (!processedSalesData) return;
    return state.filters.branches && state.filters.branches.length
      ? processedSalesData.filter(
          (r) => state.filters.branches.map((d) => d.value).indexOf(r.branchId) != -1
        )
      : processedSalesData;
  }, [processedSalesData, state.filters.branches]);

  const dseRetailerCodes = useMemo(() => {
    let beats =
      state.filters.dse && state.filters.dse.length
        ? _.flattenDepth(
            state.filters.dse.map((d) =>
              _.filter(branchSalesData, ['code', d.value]).map((f) => f.beats)
            ),
            2
          )
        : null;
    return beats && _.flatten(beats.map((b) => b.retailers));
  }, [branchSalesData, state.filters.dse]);

  const beats = useMemo(() => {
    if (!branchSalesData) return;
    return _.flatten(branchSalesData.map((b) => b.beats));
  }, [branchSalesData]);

  const mktIndexRetailerCodes = useMemo(() => {
    let mktIndexCodes =
      state.filters.mktIndex && state.filters.mktIndex.length
        ? _.flattenDepth(
            state.filters.mktIndex.map((d) =>
              _.filter(beats, ['mktIndex', d.value]).map((f) => f.retailers)
            ),
            2
          )
        : null;
    return mktIndexCodes;
  }, [beats, state.filters.mktIndex]);

  const mktNameRetailerCodes = useMemo(() => {
    let mktNameCodes =
      state.filters.mktName && state.filters.mktName.length
        ? _.flattenDepth(
            state.filters.mktName.map((d) =>
              _.filter(beats, ['mktName', d.value]).map((f) => f.retailers)
            ),
            2
          )
        : null;
    return mktNameCodes;
  }, [beats, state.filters.mktName]);

  const filteredData = useMemo(() => {
    if (!branchData) return;
    let codeData =
      state.filters.code && state.filters.code.length
        ? branchData.filter((b) => state.filters.code.map((d) => d.value).indexOf(b.code) != -1)
        : branchData;
    let names =
      state.filters.name && state.filters.name.length
        ? codeData.filter((c) => state.filters.name.map((d) => d.value).indexOf(c.name) != -1)
        : codeData;
    let phones =
      state.filters.phone && state.filters.phone.length
        ? names.filter((c) => state.filters.phone.map((d) => d.value).indexOf(c.phone) != -1)
        : names;
    let channelData =
      state.filters.channel && state.filters.channel.length
        ? phones.filter(
            (b) =>
              state.filters.channel
                .map((d) => d.value.toLowerCase())
                .indexOf(grouping[b.channel] && grouping[b.channel].toLowerCase()) != -1
          )
        : phones;
    let geotagByData =
      state.filters.geotagBy && state.filters.geotagBy.length
        ? channelData.filter(
            (c) => state.filters.geotagBy.map((d) => d.value).indexOf(c.mappedBy) != -1
          )
        : channelData;
    let geotagOnData =
      state.filters.geotagOn && state.filters.geotagOn.length
        ? geotagByData.filter(
            (c) => state.filters.geotagOn.map((d) => d.value).indexOf(c.mappedAt) != -1
          )
        : geotagByData;
    let retagData =
      state.filters.retag && state.filters.retag.length
        ? geotagOnData.filter(
            (c) => state.filters.retag.map((d) => d.value).indexOf(Boolean(c.retag)) != -1
          )
        : geotagOnData;
    let phoneVerifiedData =
      state.filters.phoneVerified && state.filters.phoneVerified.length
        ? retagData.filter(
            (c) =>
              state.filters.phoneVerified.map((d) => d.value).indexOf(Boolean(c.phoneVerified)) !=
              -1
          )
        : retagData;
    let hasImageData =
      state.filters.hasImage && state.filters.hasImage.length
        ? phoneVerifiedData.filter(
            (c) =>
              state.filters.hasImage
                .map((d) => d.value)
                .indexOf(Boolean(c.images && c.images.length)) != -1
          )
        : phoneVerifiedData;
    let mktIndexData = mktIndexRetailerCodes
      ? hasImageData.filter((c) => mktIndexRetailerCodes.includes(c.id))
      : hasImageData;
    let mktNameData = mktNameRetailerCodes
      ? mktIndexData.filter((c) => mktNameRetailerCodes.includes(c.id))
      : mktIndexData;
    let dseData = dseRetailerCodes
      ? mktNameData.filter((c) => dseRetailerCodes.includes(c.id))
      : mktNameData;
    return dseData;
  }, [branchData, dseRetailerCodes, mktIndexRetailerCodes, mktNameRetailerCodes, state.filters]);

  const filteredBranches = useMemo(() => {
    if (!filteredData || !branches) return;
    let uniqValues = Object.values(_.uniqBy(filteredData, (f) => f.branchId));
    return branches
      .filter((b) => b.latitude && b.longitude)
      .filter((b) => uniqValues.map((u) => u.branchId).indexOf(b.id) != -1);
  }, [filteredData, branches]);
  //console.log(filteredBranches);
  // const groupItems = useMemo(() => {
  //   if (!filteredData || groupByValue === 'None') return;
  //   if (groupByValue === 'Branch')
  //     return Object.entries(_.groupBy(filteredData, (f) => f.branchId));
  // }, [filteredData, groupByValue]);

  const layer = useMemo(() => {
    return [
      new ScatterplotLayer({
        id: 'retailers',
        data: filteredData,
        getPosition: (u) => [u.longitude, u.latitude, 0],
        getFillColor: (d) => getColor(d),
        radiusMinPixels: 3,
        stroked: true,
        lineWidthUnits: 'pixels',
        getLineColor: blackM,
        pickable: true,
        updateTriggers: {
          getFillColor: [groupByValue, filteredData],
        },
        onHover: ({ picked }) => {
          setHovering(picked);
        },
        onClick: (info) => {
          setShowDiv(true);
          setTooltip({
            obj: info.object,
            pointerX: info.x,
            pointerY: info.y,
          });
        },
      }),
      new IconLayer({
        id: 'branchesColor',
        data: filteredBranches,
        getPosition: (u) => [u.longitude, u.latitude, 0],
        getColor: (d) => {
          let index = _.orderBy(branches, 'id', 'asc').findIndex((b) => b.id === d.id);
          return branches.length === 1 ? colors[index] : colors[index - 1];
        },
        getIcon: () => ({
          url: branchIcon,
          width: 64,
          height: 64,
          anchorY: 64,
          mask: true,
        }),
        sizeUnits: 'pixels',
        pickable: true,
        sizeScale: 30,
        updateTriggers: {
          getColor: [filteredBranches],
        },
        onHover: ({ picked }) => {
          setHovering(picked);
        },
        onClick: (info) => {
          setShowDiv(true);
          setTooltip({
            obj: info.object,
            pointerX: info.x,
            pointerY: info.y,
          });
        },
      }),
      new IconLayer({
        id: 'branches',
        data: filteredBranches,
        getPosition: (u) => [u.longitude, u.latitude, 0],
        getColor: [255, 255, 255, 2555],
        getIcon: () => ({
          url: branchOIcon,
          width: 64,
          height: 64,
          anchorY: 64,
          mask: true,
        }),
        sizeUnits: 'pixels',
        pickable: true,
        sizeScale: 30,
        updateTriggers: {
          getColor: [filteredBranches],
        },
        onHover: ({ picked }) => {
          setHovering(picked);
        },
        onClick: (info) => {
          setShowDiv(true);
          setTooltip({
            obj: info.object,
            pointerX: info.x,
            pointerY: info.y,
          });
        },
      }),
    ];
  }, [branches, filteredData, groupByValue, filteredBranches, getColor]);

  //Filter Items
  const [codeValue, setCodeValue] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [geotagOn, setGeotagOn] = useState();

  const branchesOptions = useMemo(
    () =>
      branches
        .filter((b) => b.id != -1)
        .map((branch) => ({ value: branch.id, label: branch.name })),
    [branches]
  );

  const channelOptions = () => {
    let values = Object.keys(c).map((k) => c[k]);
    return values.map((v) => ({ value: v, label: v }));
  };

  const booleanOptions = [
    { value: true, label: 'TRUE' },
    { value: false, label: 'FALSE' },
  ];

  const retailerCodeOptions = useMemo(() => {
    if (!branchData) return;
    return Object.values(_.uniqBy(branchData, (b) => b.code)).map((b) => ({
      value: b.code,
      label: b.code,
    }));
  }, [branchData]);

  const retailerNames = useMemo(() => {
    if (!branchData) return;
    return Object.values(_.uniqBy(branchData, (b) => b.name)).map((b) => ({
      value: b.name,
      label: b.name,
    }));
  }, [branchData]);

  const retailerPhones = useMemo(() => {
    if (!branchData) return;
    return Object.values(_.uniqBy(branchData, (b) => b.phone)).map((b) => ({
      value: b.phone,
      label: b.phone || 'None',
    }));
  }, [branchData]);

  const geotagByOptions = useMemo(() => {
    if (!branchData) return;
    return Object.values(_.uniqBy(branchData, (b) => b.mappedBy)).map((b) => ({
      value: b.mappedBy,
      label: b.mappedBy
        ? _.result(_.find(branchSalesData, ['id', b.mappedBy]), 'code') || `${b.mappedBy}(id)`
        : 'Unknown',
    }));
  }, [branchData, branchSalesData]);

  const geotagOnOptions = useMemo(() => {
    if (!branchData) return;
    let uniqueValues = Object.values(
      _.uniqBy(branchData, (b) =>
        b.mappedAt ? format(Date.parse(b.mappedAt), 'dd-MMM-yy') : b.mappedAt
      )
    );
    let sortValues = _.orderBy(uniqueValues, 'mappedAt', 'asc');
    return sortValues.map((b) => ({
      value: b.mappedAt,
      label: b.mappedAt ? format(Date.parse(b.mappedAt), 'dd-MMM-yy') : 'Unknown',
    }));
  }, [branchData]);

  const marketIndexOptions = useMemo(() => {
    let uniqueValues = Object.values(_.uniqBy(beats, (bt) => bt.mktIndex));
    let sortValues = _.orderBy(uniqueValues, 'mktIndex', 'asc');
    return sortValues.map((b) => ({
      value: b.mktIndex,
      label: b.mktIndex,
    }));
  }, [beats]);

  const marketNameOptions = useMemo(() => {
    let uniqueValues = Object.values(_.uniqBy(beats, (bt) => bt.mktName));
    let sortValues = _.sortBy(uniqueValues, (u) => (u.mktName ? parseInt(u.mktName) : 'unknown'));
    return sortValues.map((b) => ({
      value: b.mktName,
      label: b.mktName,
    }));
  }, [beats]);

  const DseOptions = useMemo(() => {
    return Object.values(_.uniqBy(branchSalesData, (b) => b.code)).map((b) => ({
      value: b.code,
      label: b.code || 'None',
    }));
  }, [branchSalesData]);

  const selectItems = [
    {
      heading: 'Branch',
      options: branchesOptions,
      onChange: (v) => dispatch(['UPDATE_BRANCH', v]),
    },
    {
      heading: 'Customers Data',
      name: 'Customer Code',
      expand: retailersExpand,
      handleClick: handleRetailersClick,
      options: retailerCodeOptions,
      value: codeValue,
      onChange: (v) => {
        dispatch(['UPDATE_CODE', v]);
        setCodeValue(v);
      },
      loadMore: true,
    },
    {
      name: 'Customer Name',
      expand: retailersExpand,
      options: retailerNames,
      value: name,
      onChange: (v) => {
        dispatch(['UPDATE_NAME', v]);
        setName(v);
      },
      loadMore: true,
    },
    {
      name: 'Customer Phone',
      expand: retailersExpand,
      options: retailerPhones,
      value: phone,
      onChange: (v) => {
        dispatch(['UPDATE_PHONE', v]);
        setPhone(v);
      },
      loadMore: true,
    },
    // {
    //   name: 'Address',
    //   options: branchesOptions,
    // },
    {
      name: 'Customer Hierarchy',
      options: channelOptions(),
      expand: retailersExpand,
      onChange: (v) => dispatch(['UPDATE_CHANNEL', v]),
    },
    {
      heading: 'Geotagging',
      name: 'Geotagged By',
      expand: geotaggingExpand,
      handleClick: handleGeotaggingClick,
      options: geotagByOptions,
      onChange: (v) => dispatch(['UPDATE_GEOTAGBY', v]),
    },
    {
      name: 'Geotagged On',
      expand: geotaggingExpand,
      options: geotagOnOptions,
      value: geotagOn,
      onChange: (v) => {
        dispatch(['UPDATE_GEOTAGON', v]);
        setGeotagOn(v);
      },
      loadMore: true,
    },
    {
      name: 'Retag Status',
      expand: geotaggingExpand,
      options: booleanOptions,
      onChange: (v) => dispatch(['UPDATE_RETAG', v]),
    },
    {
      name: 'Phone Verified Status',
      expand: geotaggingExpand,
      options: booleanOptions,
      onChange: (v) => dispatch(['UPDATE_PHONE_VERIFIED', v]),
    },
    {
      name: 'Has Image',
      expand: geotaggingExpand,
      options: booleanOptions,
      onChange: (v) => dispatch(['HAS_IMAGE', v]),
    },
    {
      heading: 'Beats',
      name: 'Market Number',
      expand: beatsExpand,
      handleClick: handleBeatsClick,
      options: marketIndexOptions,
      onChange: (v) => dispatch(['UPDATE_MKTINDEX', v]),
    },
    {
      name: 'Market Name',
      expand: beatsExpand,
      options: marketNameOptions,
      onChange: (v) => dispatch(['UPDATE_MKTNAME', v]),
    },
    {
      name: 'DSE',
      expand: beatsExpand,
      options: DseOptions,
      onChange: (v) => dispatch(['UPDATE_DSE', v]),
    },
    {
      heading: 'Customer Close',
      name: 'Customer Closed Day',
      expand: shopExpand,
      handleClick: handleShopClick,
      //options: branchesOptions,
    },
    {
      name: 'Customer Closed Time',
      expand: shopExpand,
      //options: branchesOptions,
    },
    {
      heading: 'Map Area',
      //options: branchesOptions,
    },
  ];

  const sideList = () => (
    <div>
      <List>
        <ListItem>
          <p />
        </ListItem>
        {selectItems.map(
          ({ name, heading, options, expand, onChange, handleClick, loadMore, value }, index) => (
            <>
              {heading && (
                <ListItem key={index + selectItems.length} button onClick={handleClick}>
                  <div className={classes.expand}>
                    <ListItemText primary={heading} />
                    {heading && name ? expand ? <ExpandLess /> : <ExpandMore /> : null}
                  </div>
                </ListItem>
              )}
              {name ? (
                <Collapse in={expand}>
                  <ListItem key={index}>
                    <div>
                      <ListItemText classes={{ secondary: classes.ListText }} secondary={name} />
                      {loadMore ? (
                        //<Select options={options} components={{ MenuList }} onChange={onChange} isMulti/>
                        <VirtualSelect
                          options={options}
                          className={classes.Select}
                          closeOnSelect={false}
                          searchable={true}
                          onChange={onChange}
                          value={value}
                          multi
                        />
                      ) : (
                        <Select
                          closeMenuOnSelect={false}
                          className={classes.Select}
                          isMulti
                          options={options}
                          onChange={onChange}
                        />
                      )}
                    </div>
                  </ListItem>
                </Collapse>
              ) : (
                <ListItem key={index}>
                  <div>
                    <ListItemText classes={{ secondary: classes.ListText }} secondary={name} />
                    <Select
                      closeMenuOnSelect={false}
                      className={classes.Select}
                      isMulti
                      options={options}
                      onChange={onChange}
                    />
                  </div>
                </ListItem>
              )}
            </>
          )
        )}
        <ListItem>
          <ListItemText primary="Group By" />
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="GroupBy"
              name="groupBy"
              value={groupByValue}
              onChange={handleGroupBy}
            >
              <FormControlLabel value="Branch" control={<Radio />} label="Branch" />
              <FormControlLabel value="Dse" control={<Radio />} label="Beat DSE" />
              <FormControlLabel value="Number" control={<Radio />} label="Beat Market Number" />
              <FormControlLabel value="Closed" control={<Radio />} label="Shop Closed Day" />
              <FormControlLabel value="None" control={<Radio />} label="None" />
            </RadioGroup>
          </FormControl>
        </ListItem>
        <ListItem>
          <p />
        </ListItem>
      </List>
      <Divider />
    </div>
  );
  const salesRetailerCodes = useMemo(() => {
    if (!processedSalesData) return;
    let retailerCodes = processedSalesData.map((s) => {
      let retailers = _.flatten(_.flatten(s.beats).map((b) => b.retailers));
      return {
        code: s.code,
        retailers: retailers,
      };
    });
    return retailerCodes;
  }, [processedSalesData]);

  function renderToolTip() {
    const { obj, pointerX, pointerY } = tooltip || {};

    const retailerCount = (id) => {
      if (!processedData) return 0;
      return _.countBy(processedData, (p) => p.branchId === id).true;
    };
    const getDseCode = (id) => {
      if (!salesRetailerCodes) return '';
      return salesRetailerCodes.filter((s) => s.retailers.includes(id)).map((r) => `${r.code} `);
    };
    return obj ? (
      <div
        style={{
          display: showDiv ? 'unset' : 'none',
          position: 'absolute',
          zIndex: 1,
          pointerEvents: 'all',
          left: pointerX,
          top: pointerY,
          cursor: 'pointer',
        }}
      >
        <Box bgcolor="background.paper" p={1} borderRadius={4}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TC text={obj.branchId && obj.id ? obj.code : obj.name} color="primary" />
                <TC
                  text={obj.id && obj.branchId ? byId(branches, obj.branchId).name : 'Branch'}
                  align="right"
                  color="textSecondary"
                ></TC>
              </TableRow>
            </TableHead>
            {obj.branchId ? (
              <TableBody>
                <TR label="Name" value={obj.name} />
                <TR label="DSEs" value={getDseCode(obj.id)} />
                <TR label="Channel" value={obj.channel} />
                <TR label="Phone" value={obj.phone} />
                <TR image={obj.images[0]} />
              </TableBody>
            ) : (
              <TableBody>
                <TR label="Customer Count" value={retailerCount(obj.id)} />
              </TableBody>
            )}
          </Table>
        </Box>
      </div>
    ) : null;
  }
  // function CustomItem({ name, options, onChange, index }) {
  //   return (
  //     <ListItem key={index}>
  //       <div>
  //         <ListItemText classes={{ secondary: classes.ListText }} secondary={name} />
  //         <Select className={classes.Select} isMulti options={options} onChange={onChange} />
  //       </div>
  //     </ListItem>
  //   );
  // }

  // return(
  //   <div id="map"/>
  // );
  return (
    <div className={classes.root}>
      <Box zIndex="modal">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={clsx(classes.fab, {
            [classes.hide]: open,
          })}
        >
          <FilterList />
        </IconButton>
      </Box>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {/* <div className={classes.drawerClose} /> */}
        {(!state.filteredRetailerData || !state.filteredRetailerData.length) && (
          <LinearProgress color="secondary" />
        )}
        {initialViewState.latitude && (
          <DeckGL
            initialViewState={initialViewState}
            controller={true}
            layers={layer}
            getCursor={() => (hovering ? 'pointer' : 'grab')}
            onClick={onMapClick}
          >
            <StaticMap
              mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
              mapStyle={'mapbox://styles/mapbox/navigation-guidance-day-v4'}
            />
            {renderToolTip()}
          </DeckGL>
        )}
      </main>
      <Drawer
        className={classes.drawer}
        anchor="right"
        variant="persistent"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box height={1}>
          {sideList()}
          <Box
            bottom={0}
            maxHeight={0.06}
            position="fixed"
            top="auto"
            bgcolor="primary.main"
            width={1}
          >
            <div className={classes.drawerClose}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronRight fontSize="small" /><Typography>HEY</Typography>
              </IconButton>
            </div>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
