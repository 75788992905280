import 'react-virtualized-select/styles.css';
import 'react-virtualized-select/node_modules/react-select/dist/react-select.css';
import 'react-virtualized/styles.css';

import React from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import ReactSelect from 'react-select';

export default function Select(props) {
  return <ReactSelect {...props} />;
}

export function VirtualSelect(props) {
  return <VirtualizedSelect {...props} />;
}
