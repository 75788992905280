import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { ga, useGDispatch, useGState } from 'state/store';
import { useAPI } from 'utils/customHooks';

export default function UserProfile() {
  const loggedInUser = useGState((s) => s.user);

  const [fetch] = useAPI();
  const gDispatch = useGDispatch();
  const handleLogout = () =>
    fetch(
      {
        method: 'post',
        url: `/cookie/logout`,
      },
      () => {
        gDispatch([ga.USER, null]);
      }
    );
  return (
    <div>
      <Box flexGrow={1} pl={1} align="center">
        <Typography variant="h6">{loggedInUser?.loginId}</Typography>
        <Button variant="outlined" color="primary" onClick={() => handleLogout()}>
          Logout
        </Button>
      </Box>
    </div>
  );
}
